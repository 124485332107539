import React, { useContext, useEffect, useState } from 'react';
import ExternalIcon from '../../assets/icons/external-icon.svg'
import { ExternalMusicContext, ExternalMusicContextType } from '../../context/ExtenalMusicContext';
import MusicPlayer from '../../pages/Music/MusicPlayer';
import styles from '../../styles/ExternalMusicPlayer.module.css'
import Image from '../helpers/Image';

const ExternalMusicPlayer:React.FC = () => {
  const { data, changeData } = useContext(ExternalMusicContext) as ExternalMusicContextType 
  const [isMouseIn, setIsMouseIn] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);

  useEffect(() => {
    setIsPlaying(true);
    setIsMouseIn(false);
  }, [data])

  if(!data) return null;

  return (
    <div 
      onClick={() => setIsMouseIn(true)} 
      onMouseEnter={() => setIsMouseIn(true)} 
      onMouseLeave={() => setIsMouseIn(false)} 
      className={styles.musicContainer}
    >
      <div className={`${styles.album}`}>
        <Image url={data.album_image} alt={data.music_name} />
        <button onClick={() => changeData(null)} className={styles.closeButton}>
          X
        </button>
        <a
          onClick={data.music_author_link && data.music_author_link.length === 0 ? (e) => e.preventDefault() : undefined}  
          href={data.music_author_link && data.music_author_link} 
          target="blank"
        >
          {
            data.music_author_image.length > 0 && (
              <img className={styles.authorLogo} src={data.music_author_image} alt="" />
            )
          }
        </a>
      </div> 
      <div className={`${styles.content} ${ isMouseIn ? null : styles.hiddenPlayer }`} >
        <h3 className={`${isPlaying ? styles.rollingTitle : ''}`}>
          {data.music_name}
        </h3>
        <h4>
          <a 
            onClick={data.music_author_link && data.music_author_link.length === 0 ? (e) => e.preventDefault() : undefined} 
            href={data.music_author_link && data.music_author_link} 
            target="blank"
          >
            {data.music_author_name}
            {
              data.music_author_link && data.music_author_link.length > 0 ? (
              <img src={ExternalIcon} alt="External Link" /> 
              ) : null
            }
          </a>
        </h4>
        <MusicPlayer  
          url={data.music_item_file}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          playbackRateOn={false}
          playSize="small"
          autoplay={true}
        />
      </div>
        
    </div>
  )
}

export default ExternalMusicPlayer