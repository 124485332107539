import React from 'react';
import styles from '../../styles/CharacterTable.module.css';

interface CharacterTableProps {
  table: {
    tableActive: string;
    tableAdvantage: string;
    tableDamage: string;
    tableGuard: string;
    tableRecovery: string;
    tableStartup: string;
  }
}

const CharacterTable:React.FC<CharacterTableProps> = ({table}) => {
  return (
  <div className={styles.container}>
    <table className={styles.table}>
      <tbody>
        <tr>
        <th>damage</th>
        <th>guard</th>
        <th>start up</th>
        <th>active</th>
        <th>recovery</th>
        <th>advantage</th>       
        </tr>
        <tr>
        <td>{table.tableDamage}</td>
        <td>{table.tableGuard}</td>
        <td>{table.tableStartup}</td>
        <td>{table.tableActive}</td>
        <td>{table.tableRecovery}</td>
        <td>{table.tableAdvantage}</td>
        </tr>
      </tbody>
    </table>
  </div>
  )
}

export default CharacterTable