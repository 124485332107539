import React, { useContext } from 'react'
import { ExternalMusicContext, ExternalMusicContextType } from '../../context/ExtenalMusicContext';
import { MusicData } from '../../pages/Music';
import styles from '../../styles/ExternalMusicButton.module.css'
import popUpIcon from '../../assets/icons/popup-icon.svg';

interface ExternalMusicButtonProps {
  data: MusicData;
}

const ExternalMusicButton:React.FC<ExternalMusicButtonProps> = ({data}) => {
  const { changeData } = useContext(ExternalMusicContext) as ExternalMusicContextType;
  let windowSize = window.innerWidth;

  if(windowSize < 650) return null;

  return (
    <button className={styles.button} onClick={() => changeData(data)}>
      <img src={popUpIcon} alt="Active Music Pop up" />
    </button>
  )
}

export default ExternalMusicButton