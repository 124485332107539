import React, { useEffect, useState } from 'react'
import { getHomeData } from '../../api/Api';
import Loading from '../../components/helpers/Loading';
import About, { AboutRequestData } from './About'
import useFetch from '../../hooks/useFetch'
import Mechanics, { MechanicsRequestData } from './Mechanics';
import PickCharacters, { CharacterRequestData } from './PickCharacters';
import Head from '../../components/helpers/Head';
import Error404 from '../Error404';
import useSessionStorage from '../../hooks/useSessionStorage';
export interface HomeRequestData {
  page_id: number;
  about: AboutRequestData;
  character: CharacterRequestData;
  mechanics: MechanicsRequestData;
}

const Home:React.FC = () => {
  const { loading, error , request } = useFetch<HomeRequestData>();
  const [localData, setLocalData] = useState<HomeRequestData | null>(null);
  const { value, setValueInStorage } = useSessionStorage<HomeRequestData>('home');

  useEffect(() => {
    async function getHome() {
      const {url, options} = getHomeData();
      const json = await request({url, options});
      
      if(json) {
        setLocalData(json);
        setValueInStorage(json);
      }
    }
    
    if(!value) {
      getHome();
    } else {
      setLocalData(value)
    }
    
  }, [request, value, setValueInStorage])

  
  if(loading) return <Loading type='page' />;
  if(error) return <Error404 />;
  if(!localData) return null;

  return (
    <main className='marginMain animaOpacityDown'>
      <Head />
      <About aboutData={localData.about} />
      <Mechanics mechanicsData={localData.mechanics} />
      <PickCharacters characterData={localData.character} />
    </main>
  )
}

export default Home