const stringToValidId = (text:string) => {
  let formatedText = text;

  formatedText = formatedText.split(' ').join('-');
  formatedText = formatedText.split('/').join('-');
  formatedText = formatedText.replaceAll('"', '');
  
  return formatedText;
}

export default stringToValidId;