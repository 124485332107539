import React from 'react';
import styles from '../../styles/Paragraph.module.css';

interface ParagraphProps {
  children: React.ReactNode;
  font?: string; 
}

const Paragraph:React.FC<ParagraphProps> = ({children, font}) => {
  return (
    <p style={{
      fontSize: font ? font : ''
    }} className={styles.paragraph}>
      {children}
    </p>
  )
}

export default Paragraph;