import React, {  useMemo, useRef, useState } from 'react'
import styles from '../../styles/MusicPlayer.module.css'
import secToMin from '../../utils/secToMin'
import volumeIcon from '../../assets/icons/volume-icon.svg'
import volumeMutedIcon from '../../assets/icons/volume-muted-icon.svg'

interface MusicPlayerProps {
  url: string;
  isPlaying: boolean;
  setIsPlaying: (value:boolean) => void;
  playbackRateOn?: boolean;
  playSize?: 'small' | 'normal';
  autoplay?: boolean;
}

const MusicPlayer:React.FC<MusicPlayerProps> = ({
  url, 
  isPlaying ,
  setIsPlaying, 
  playbackRateOn = true,
  playSize = 'normal',
  autoplay = false
}) => {
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [selectVelocity, setSelectVelocity] = useState<boolean>(false);
  const progressRef = useRef<HTMLInputElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null); 
 
  const handleProgressBar = () => {
    if(!audioRef.current || !progressRef.current) return;
    audioRef.current.currentTime = Number(progressRef.current.value);
  }

  const handleTimeUpdate = () => {
    if(!audioRef.current) return;
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleVolume = ({target}:{target:HTMLInputElement}) => {
    if(!audioRef.current) return;
    audioRef.current.volume = Number(target.value) / 100;
  };

  const handleMute = () => {
    if(!audioRef.current) return;
    if(audioRef.current.muted) audioRef.current.muted = false
    else audioRef.current.muted = true
  };

  const handleMusicVelocity = ({target}:{target:HTMLSelectElement}) => {
    if(!audioRef.current) return;
    audioRef.current.playbackRate = Number(target.value);
  };

  const renderButtonSizes = useMemo(()=> {
    const handleClick = () => {
      if(!audioRef.current) return;
      if(isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play();
        setIsPlaying(true);
      }
    }

    return {
    small: 
    <button onClick={handleClick} className={`${isPlaying ? styles.pauseSmallButton : styles.playSmallButton}`}>
    </button>,
    normal:(
    <button onClick={handleClick} className={`${isPlaying ? styles.pauseButton : styles.playButton}`}>
    </button>),
    
  }}, [isPlaying, setIsPlaying])
  
  return (
    <>
      <audio autoPlay={autoplay} onTimeUpdate={handleTimeUpdate} ref={audioRef} preload='metadata' src={url}></audio>
      <div className={styles.player}>
        {renderButtonSizes[playSize]}
        <div>
          <div className={styles.volumeContainer}>
            {
              audioRef.current && audioRef.current.muted ? 
              (
              <img onClick={handleMute} className={styles.volumeIcon} src={volumeMutedIcon} alt="Volume Muted" />
              ) : 
              ( 
              <img onClick={handleMute} className={styles.volumeIcon} src={volumeIcon} alt="Volume" />
              )
            }
            <input 
              className={styles.volume}
              type="range" 
              min="0"
              max="100"
              onChange={handleVolume}
            />
          </div>
          <input 
            ref={progressRef}
            value={currentTime}
            type="range" 
            step="any"
            min="0"
            max={audioRef.current && !isNaN(audioRef.current.duration) ? audioRef.current.duration : 100}
            onChange={handleProgressBar}
            data-initime={currentTime ? secToMin(currentTime) : '0:00'} 
            data-fintime={audioRef.current && secToMin(audioRef.current.duration)} 
          />
          {
            playbackRateOn && (
              <div className={styles.velocityContainer}>
              <button onClick={() => setSelectVelocity(curr => !curr)}>
                ...
              </button>
              {
                selectVelocity && (
                <select defaultValue="" onChange={handleMusicVelocity}  className={styles.velocity}>
                  <option value="">Select</option>
                  <option value=".25">.25</option>
                  <option value=".5">.5</option>
                  <option value="1">1</option>
                  <option value="1.25">1.25</option>
                  <option value="1.5">1.5</option>
                  <option value="2">2</option>
                </select>
                )
              }
              
            </div>
            )
          }

        </div>
      </div>
    </>
  )
}

export default MusicPlayer