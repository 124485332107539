export const baseUrl = 'https://sakuraxclash.com/wordpress_api/wp-json/sxc/api/v1';

export const getHomeData = () => {
  return {
    url: `${baseUrl}/home`,
    options: {
      "method": 'GET'
    }
  }
}

export const getWorldData = () => {
  return {
    url: `${baseUrl}/world`,
    options: {
      "method": 'GET'
    }
  }
}

export const getMechanicsData = () => {
  return {
    url: `${baseUrl}/mechanics`,
    options: {
      "method": 'GET'
    }
  }
}

export const getPortraitsData = () => {
  return {
    url: `${baseUrl}/portraits`,
    options: {
      "method": 'GET'
    }
  }
}

export const getFooterLinksData = () => {
  return {
    url: `${baseUrl}/footer`,
    options: {
      "method": 'GET'
    }
  }
}

export const getCharactersData = (name:string) => {
  return {
    url: `${baseUrl}/characters/${name}`,
    options: {
      "method": 'GET'
    }
  }
}

export const getMusicData = () => {
  return {
    url: `${baseUrl}/music`,
    options: {
      "method": "GET"
    }
  }
}

export const getDownloadsData = () => {
  return {
    url: `${baseUrl}/downloads`,
    options: {
      "method": "GET"
    }
  }
}