import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import smoothScrollTo from '../../utils/smoothScrollTo';
import styles from '../../styles/InternalNav.module.css';
import InternalGroupLinks from './InternalGroupLinks';
import stringToValidId from '../../utils/stringToValidId';

interface InternalNavProps {
  navigationLinks: {
    offset?:number;
    navigationHeader: string | null;
    navigationLink: string | string[];
  }[];
}

const InternalNav:React.FC<InternalNavProps> = ({ navigationLinks }) => {
  const [ toggle, setToggle ] = useState<boolean>(false);  

  return (
    <nav className={`${styles.container}`}>
      <button data-tip='Menu' className={`${styles.button} ${toggle && styles.closeButton}`} onClick={() => setToggle(curr => !curr)}>
        <span></span>
      </button>
      {
        toggle && (
          <div className={`${toggle && styles.border}`}>
            <h2 className={styles.title}>
              Navigation
            </h2>
            <ul className={styles.navigation}>
              {
                navigationLinks.map((link) => {
                  if(Array.isArray(link.navigationLink)) {
                    return (
                      <InternalGroupLinks key={link.navigationHeader} link={link}/>
                    );
                  };
                  if(link.navigationHeader) {
                    return (
                      <li className={styles.link} key={link.navigationLink} >
                        <Link onClick={(e) => {
                          if(link.offset) {
                            smoothScrollTo(e, link.offset)
                          } else {
                            smoothScrollTo(e)
                          }
                        }} to={`#${stringToValidId(link.navigationLink)}`} >
                          {link.navigationHeader}
                        </Link>
                      </li>
                    )
                  }
                  return null;
                })
              }
            </ul>
          </div>
        )
      }

    </nav>
  )
}

export default InternalNav