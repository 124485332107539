import React, { useEffect, useState } from 'react';
import { getMechanicsData } from '../api/Api';
import Head from '../components/helpers/Head';
import InternalNav from '../components/helpers/InternalNav';
import Loading from '../components/helpers/Loading';
import Image from '../components/helpers/Image';
import ToggleBox from '../components/helpers/ToggleBox';
import Paragraph from '../components/ui/Paragraph';
import Title from '../components/ui/Title';
import useFetch from '../hooks/useFetch';
import useSessionStorage from '../hooks/useSessionStorage';
import styles from '../styles/Mechanics.module.css';
import { splitPragraph } from '../utils/splitParagraph';
import stringToValidId from '../utils/stringToValidId';
import Error404 from './Error404';

interface MechanicsRequestData {
  page_id: number;
  mechanics: [string, {
    mechanic_type_ID: string;
    mechanic_box_name: string;
    mechanic_box_description: string;
    mechanic_box_image: string;
    mechanic_box_image_id: number;
  }[]][]
}

const Mechanics:React.FC = () => {
  const { error, loading, request } = useFetch<MechanicsRequestData>();
  const [localData, setLocalData] = useState<MechanicsRequestData | null>(null);
  const { value, setValueInStorage } = useSessionStorage<MechanicsRequestData>('mechanics');

  useEffect(() => {
    async function getMechanis() {
      const { url, options } = getMechanicsData();

      const json = await request({url, options});
      if(json) {
        setLocalData(json);
        setValueInStorage(json);
      }
    }

    if(!value) {
      getMechanis();
    } else {
      setLocalData(value);
    }
  }, [request, value, setValueInStorage]);

  if(loading) return <Loading type='page' />;
  if(error) return <Error404 />;
  if(!localData) return null;
  
  return (
    <>
      <InternalNav 
        navigationLinks={[
          {
            offset: 200,
            navigationHeader: 'Mechanics',
            navigationLink: localData.mechanics.map(mechanics => mechanics[0])
          }
        ]}
      />
      <main className={`${styles.moreMargin} ${styles.container} animaOpacityDown`}>
        <Head title='Mechanics' />
        <Title type='h2' >
          mechanics
        </Title>
        {localData.mechanics.map(mechanic => {
          return (
            <ToggleBox key={mechanic[0]} titleBox={mechanic[0]} >
              <ul id={stringToValidId(mechanic[0])} className='animaDown' >
                {
                  mechanic[1].map(boxData => {
                    const description = splitPragraph(boxData.mechanic_box_description);
                  return (
                    <li className={styles.boxWrapper} key={boxData.mechanic_box_image_id} >
                      <Image url={boxData.mechanic_box_image} alt="" />
                      <Paragraph font='var(--font-basis)' >
                        {
                          description.map(text => <React.Fragment key={text} >&emsp; {text} <br /></React.Fragment>)
                        }
                      </Paragraph>
                    </li>
                    )
                  })
                }
              </ul>
            </ToggleBox>
            )
          })
        }
      </main>
    </>
  )
}

export default Mechanics