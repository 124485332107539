import React, { useState, useEffect } from 'react';
import { getFooterLinksData } from '../../api/Api';
import useFetch from '../../hooks/useFetch';
import useSessionStorage from '../../hooks/useSessionStorage';
import styles from '../../styles/Footer.module.css';
import Loading from '../helpers/Loading';
interface FooterRequestData {
  page_id: number;
  footer_links: {
    footer_link: string;
    footer_title_link: string;
  }[]
}

const Footer:React.FC = () => {
  const { loading, request } = useFetch<FooterRequestData>();
  const [localData, setLocalData] = useState<FooterRequestData | null>(null);
  const { value, setValueInStorage } = useSessionStorage<FooterRequestData>('footer');

  useEffect(() => {
    async function getFooter() {
      const {url, options} = getFooterLinksData();
      const json = await request({url, options});
      if(json) {
        setLocalData(json);
        setValueInStorage(json);
      }
    }
    
    if(!value) {
      getFooter();
    } else {
      setLocalData(value)
    }

  }, [request, value, setValueInStorage])

  return (
    <footer className={styles.container}>
      <h2 className={styles.title}>
        Follow the project  
      </h2>
      {
        loading && !localData ? <Loading type='circular' /> : (
          <ul className={styles.list}>
            {localData?.footer_links.map(({footer_link, footer_title_link}) => {
              return (
                <li key={footer_link}>  
                  <a href={footer_link}  target="blank" rel='external'>{footer_title_link}</a>
                </li>
              )
            })}
          </ul>
        )
      }
    </footer>
  )
}

export default Footer