import { useCallback, useMemo } from "react";

const useSessionStorage = <valueType>(key:string) => {
  const value = useMemo(() => {
    const getValue = window.sessionStorage.getItem(key);
    const parsedValue:valueType = getValue && JSON.parse(getValue);

    return parsedValue;
  }, [key]) 

  const setValueInStorage = useCallback((value:valueType) => {
    const stringValue = JSON.stringify(value);
    window.sessionStorage.setItem(key, stringValue);
  }, [key]);

  return {
    value,
    setValueInStorage
  };

};

export default useSessionStorage;