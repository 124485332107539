import React from 'react'
import { Link } from 'react-router-dom';
import ErrorImage from '../assets/404.png'; 
import Head from '../components/helpers/Head';
import styles from '../styles/Error404.module.css'

const Error404:React.FC = () => {
  return (
    <main className={`${styles.container} animaOpacityDown`}>
      <Head title='404' />
      <img src={ErrorImage} alt="404 Page not Found" />
      <div className={styles.messageContainer}>
        <h2>
         | Error 404 |
        </h2>
        <h2>
         | Page not found |
        </h2>
        <h3>
          Return to 
          <Link to="/" >
            Home
          </Link>
        </h3>
      </div>
    </main>
  )
}

export default Error404