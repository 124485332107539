import React, { useEffect, useState } from 'react'
import Head from '../components/helpers/Head';
import styles from '../styles/Download.module.css'
import useFetch from '../hooks/useFetch';
import Loading from '../components/helpers/Loading';
import Error404 from './Error404';
import useSessionStorage from '../hooks/useSessionStorage';
import { getDownloadsData } from '../api/Api';

interface DownloadRequestData {
  page_id: number
  downloads: {
    download_label: string
    download_link: string
  }[]
}

const Downloads:React.FC = () => {
  const {request,loading,error} = useFetch<DownloadRequestData>();
  const {value, setValueInStorage} = useSessionStorage<DownloadRequestData>("download/page");
  const [data, setData] = useState<DownloadRequestData | null>(null);

  useEffect(() => {
    async function getDownloads() {
      const {url,options} = getDownloadsData();
      const json = await request({url, options});
      if(json) {
        setData(json);
        setValueInStorage(json);
      }
    };

    if(!value) {
      getDownloads();
    } else {
      setData(value);
    }

  }, []);

  if(loading) return <Loading type='page' />;
  if(error || !data) return <Error404 />;

  return (
    <main className={`marginMain animaOpacityDown ${styles.mainContainer}`}>
      <Head 
        title='Sakura X Clash - Download Now!'
      />
      <h3 className={styles.title} >
        Download Sakura X Clash at:
      </h3>
      <ul className={styles.linkContainer}>
        {
          data.downloads.map(link => {
            return (
            <li key={link.download_link} >
              <a className={styles.link} href={link.download_link} target='_blank'>
                {link.download_label}
              </a>
            </li>
            );
          })
        }
      </ul>
    </main>
  ) 
}

export default Downloads;