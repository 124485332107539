import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Characters from './pages/Characters';
import Error404 from './pages/Error404';
import Home from './pages/Home';
import Mechanics from './pages/Mechanics';
import World from './pages/World';
import './App.css';
import Footer from './components/ui/Footer';
import Header from './components/ui/Header';
import Music from './pages/Music';
import ExternalMusicPlayer from './components/ui/ExternalMusicPlayer';
import ExtenalMusicProvider from './context/ExtenalMusicContext';
import CookieBanner from './components/ui/CookieBanner';
import Downloads from './pages/Downloads';

function App() {
  
  return (
    <div className='container' >
      <ExtenalMusicProvider>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/world' element={<World />} />
            <Route path='/mechanics' element={<Mechanics />} />
            <Route path='/characters/:name' element={<Characters />} />
            <Route path='/music' element={<Music />} />
            <Route path='/download' element={<Downloads />} />
            <Route path='*' element={<Error404 />} />
          </Routes>
          <ExternalMusicPlayer />
          <CookieBanner />
          <Footer />
        </BrowserRouter>
      </ExtenalMusicProvider>
    </div>
  );    
}

export default App;
