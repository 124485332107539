import loadingGif from '../../assets/loading.gif'
import styles from '../../styles/Loading.module.css';

interface LoadingProps {
  type: 'page' | 'circular'
}

const Loading:React.FC<LoadingProps> = ({type}) => {
  switch(type) {
    case 'page':
    return (
      <main>
        <div className={styles.loadingContainer}>
          <div className={styles.loadingContent}>
            <img src={loadingGif} alt="Loading gif" />
            <span>
            </span>
          </div>
        </div>
      </main>
    )
    case 'circular':
      return <div className={styles.circularLoading}></div>
    default: 
      return null
  }
}

export default Loading