import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getCharactersData } from '../../api/Api';
import Head from '../../components/helpers/Head';
import InternalNav from '../../components/helpers/InternalNav';
import Loading from '../../components/helpers/Loading';
import useFetch from '../../hooks/useFetch';
import useSessionStorage from '../../hooks/useSessionStorage';
import capitalizeString from '../../utils/capitalizeString';
import Error404 from '../Error404';
import CharacterColors, { CharacterColorsData } from './CharacterColors';
import CharacterMoves, { CharacterMovesData } from './CharacterMoves';
import CharacterProfile, { CharacterProfileData } from './CharacterProfile';
import CharacterProsCons, { CharacterProsConsData } from './CharacterProsCons';
interface CharactersRequestData {
  character_profile: CharacterProfileData;
  moves: CharacterMovesData;
  colors: string | CharacterColorsData;
  pros_cons: CharacterProsConsData;
}

const Characters:React.FC = () => {
  const { name } = useParams();
  const { loading, error , request} = useFetch<CharactersRequestData>();
  const [localData, setLocalData] = useState<CharactersRequestData | null>(null);
  const { value, setValueInStorage } = useSessionStorage<CharactersRequestData>(`characters/${name}`);

  useEffect(() => {
    async function getCharacter() {
      if(!name) return;
      const { url, options } = getCharactersData(name);

      const json = await request({url, options});
      if(json) {
        setLocalData(json);
        setValueInStorage(json);
      }
    }

    if(!value) {
      getCharacter();
    } else {
      setLocalData(value);
    }

  },[name, request, value, setValueInStorage])

  if(loading) return <Loading type='page' />;
  if(error) return <Error404 />;
  if(!localData) return null;
  
  return (
    <>
      <InternalNav 
        navigationLinks={
          [
            {
              offset: 200,
              navigationHeader: 'Profile',
              navigationLink: localData.character_profile.character_name.replaceAll('"', '')
            },
            {
              offset: 200,
              navigationHeader: (Array.isArray(localData.pros_cons.pros) && Array.isArray(localData.pros_cons.pros)) ? 'Pros/Cons' : null,
              navigationLink: 'pros-cons'
            },
            {
              offset: 200,
              navigationHeader: 'Moves',
              navigationLink: localData.moves.map(move => move[0])
            },
            { 
              offset: 150,
              navigationHeader: localData.colors ? 'Colors' : null ,
              navigationLink: 'colors'
            }
          ]
        }
      />
      <main className='marginMain animaOpacityDown'>
        <Head title={name && capitalizeString(name)} />
        <CharacterProfile profile={localData.character_profile} />
        {(Array.isArray(localData.pros_cons.pros) && Array.isArray(localData.pros_cons.pros)) &&
        <CharacterProsCons prosCons={localData.pros_cons} /> }
        <CharacterMoves moves={localData.moves} />
        {Array.isArray(localData.colors) && <CharacterColors colors={localData.colors} /> }
      </main>
    </>

  )
}

export default Characters