import React, { useMemo } from 'react'
import Paragraph from '../../components/ui/Paragraph';
import { splitPragraph } from '../../utils/splitParagraph';
import styles from '../../styles/CharacterProfileTypes.module.css'
import { CharacterProfileData } from './CharacterProfile';

export type ProfileTypes = 'story' | 'characteristics' | 'overview';

interface CharacterProfileTypesProps {
  data: CharacterProfileData;
  type: ProfileTypes;
}

const CharacterProfileTypes:React.FC<CharacterProfileTypesProps> = ({type, data}) => {
  const story = useMemo(() => splitPragraph(data.character_story), [data.character_story]);
  const overviewDescription = useMemo(() => splitPragraph(data.character_overview.description), [data.character_overview.description]);

  switch(type) {
    case 'story':
      return (
        <Paragraph>
          {story.map(text => <React.Fragment key={text}>{text} <br /> </React.Fragment>)}
        </Paragraph>
      )
    case 'characteristics':
      return (
        <ul className={styles.characteristics}>
          {
            data.character_characteristics.map((characteristic, index) => {
              return (
                <li key={index} >
                  <span>
                    {characteristic[0]}: 
                  </span>
                  {characteristic[1]}
                </li>
              )
            })
          }
        </ul>
      )
    case 'overview':
      return (
        <div className={styles.overview}>
          <h4>
            Archetype:
            <span>{data.character_overview.archetype}</span>
          </h4>
          <h4>
            Status:
          </h4>
          <ul className={styles.overviewStatus}>
            {data.character_overview.status.map(status => {
              return (
                <li key={status[0]} >
                  <span>
                    {status[0]}:
                  </span>
                  {status[1]}
                </li>
              )
            })}
          </ul>
          <Paragraph font='var(--font-basis)' >
            {overviewDescription.map(text => <React.Fragment key={text}> {text} <br /> </React.Fragment>)}
          </Paragraph>
        </div>
      )
    default: 
     return null;
  } 
}

export default CharacterProfileTypes