import React, { useState } from 'react';
import { MusicData } from '.';
import Image from '../../components/helpers/Image';
import styles from '../../styles/MusicPlayerContent.module.css';
import MusicPlayer from './MusicPlayer';
import ExternalIcon from '../../assets/icons/external-icon.svg'
import ExternalMusicButton from '../../components/ui/ExternalMusicButton';

interface MusicPlayerContentProps {
  data: MusicData;
}

const MusicPlayerContent:React.FC<MusicPlayerContentProps> = ({data}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  return (
    <li className={styles.musics}>
      <div className={styles.music}>
        <ExternalMusicButton data={data} />
        <div className={styles.album}>
          <Image url={data.album_image} alt={data.music_name} />
          <a 
            onClick={data.music_author_link && data.music_author_link.length === 0 ? (e) => e.preventDefault() : undefined} className={`${data.music_author_link && data.music_author_link.length > 0 ? styles.authorLinkHover : ''}`} 
            data-author={data.music_author_name} 
            href={data.music_author_link && data.music_author_link} 
            target="blank"
          >
            {
              data.music_author_image.length > 0 && (
                <img className={styles.authorLogo} src={data.music_author_image} alt="" />
              )
            }
          </a>
        </div>
        <div>
          <h3 className={`${isPlaying ? styles.rollingTitle : ''}`}>
            {data.music_name}
          </h3> 
          <h4>
            <a onClick={data.music_author_link && data.music_author_link.length === 0 ? (e) => e.preventDefault() : undefined} href={data.music_author_link} target="blank">
              {data.music_author_name} 
              {
                data.music_author_link && data.music_author_link.length > 0 ? (
                <img src={ExternalIcon} alt="External Link" /> 
                ) : null
              }
            </a>
          </h4> 
          <MusicPlayer url={data.music_item_file} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
        </div>
      </div>
    </li>
  )
}

export default MusicPlayerContent