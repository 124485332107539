import React, { useState } from 'react'
import CharacterProfileTypes, { ProfileTypes } from './CharacterProfileTypes';
import styles from '../../styles/CharacterProfileDescriptions.module.css';
import { CharacterProfileData } from './CharacterProfile';

const profileTypes = ['story', 'characteristics', 'overview'] as ProfileTypes[];

interface CharacterProfileDescriptionsProps {
  profile: CharacterProfileData
}

const CharacterProfileDescriptions:React.FC<CharacterProfileDescriptionsProps> = ({ profile }) => {
  const [ selectedType, setSelectedType ] = useState<ProfileTypes>('story');

  return (
  <div className={styles.descriptions}>
    <nav>
      <ul className={styles.descriptionTypes}>
        {
          profileTypes.map((type) => {
            return (
              <li key={type} onClick={() => setSelectedType(type) } className={type === selectedType ? styles.selected : ''} >
                {type}
              </li>
            )
          })
        }
      </ul>
    </nav>
    <div className={styles.animation} >
      <CharacterProfileTypes data={profile} type={selectedType} />
    </div>
  </div>
  )
}

export default CharacterProfileDescriptions