import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../assets/logo.png';
import CharactersPicker from './CharactersPicker';
import separator from '../../assets/icons/separator.svg';
import styles from '../../styles/Header.module.css';
import smoothScrollTo from '../../utils/smoothScrollTo';
import { Link, useLocation } from 'react-router-dom';

const Header:React.FC = () => {
  const [charactersMenu, setCharactersMenu] = useState<boolean>(false);
  const headerRef = useRef<HTMLElement>(null);
  const [isHome, setIsHome] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    setIsHome(pathname === '/')
    setCharactersMenu(false);

  }, [location])

  useEffect(() => {
    function outsideClick(e:MouseEvent) {
      const target = e.target as HTMLElement;
      if(!headerRef.current) return;
      const result = headerRef.current.contains(target);

      if(!result) setCharactersMenu(false);
    } 

    if(!isHome) {
      window.addEventListener('click',outsideClick);
    }

    return () => {
      window.removeEventListener('click', outsideClick);
    };

  }, [isHome])

  return (
    <header ref={headerRef} className={styles.tagHeader}>
      <div className={styles.header}>
        <nav className={styles.navHeader}>
          <ul className={styles.listHeader} role={'menu'}>
            <li role={'menuitem'}>
              <Link  to="/world">the world</Link>
            </li>
            <li>
              <img src={separator} alt="" />
            </li>
            <li role={'menuitem'}>
              <Link  to="/mechanics">mechanics</Link>
            </li>
          </ul>
          {
            isHome ? (
              <Link  className={styles.logo} onClick={(e) => smoothScrollTo(e, 150)} to='#about'>
                <h1><img src={Logo} alt="Sakura X Clash" /></h1>
              </Link>
            ) : (
              <Link  className={styles.logo} to="/">
                <h1><img src={Logo} alt="Sakura X Clash" /></h1>
              </Link>
            )
          }
          <ul className={styles.listHeader} role={'menu'}>
            <li role={'menuitem'}>
              <Link  to="/music">Music</Link>
            </li>
            <li>
              <img src={separator} alt="" />
            </li>
            <li role={'menuitem'}>
              {isHome ? (
                <Link onClick={(e) => smoothScrollTo(e, 150)} to="#characters">characters</Link>
              ):(
                <button className={`${styles.arrow} ${charactersMenu ? styles.arrowUp : styles.arrowDown}`} onClick={() =>  setCharactersMenu(curr => !curr) }>
                  characters
                </button>
              )}
            </li>
          </ul>
        </nav>
      </div>
      <div className={styles.divStick}>
        {!isHome && charactersMenu && (
          <nav className={styles.navCharacters}>
            <CharactersPicker />
          </nav>
          )
        }
      </div>
    </header>

  )
}

export default Header;