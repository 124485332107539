import React from 'react'
import ToggleBox from '../../components/helpers/ToggleBox';
import Paragraph from '../../components/ui/Paragraph';
import Title from '../../components/ui/Title';
import { splitPragraph } from '../../utils/splitParagraph';
import CharacterTable from './CharacterTable';
import styles from '../../styles/CharacterMoves.module.css';
import stringToValidId from '../../utils/stringToValidId';
import Image from '../../components/helpers/Image';
import CharacterMoveHitbox from './CharacterMoveHitbox';

export type CharacterMovesData = [string, {
  move_title: string;
  move_subtitle: string;
  move_description: string;
  move_images: [string] | [string, string];
  move_image_subtitle: string;
  move_table_status: 'on' | 'off';
  move_table_active: string;
  move_table_advantage: string;
  move_table_damage: string;
  move_table_guard: string;
  move_table_recovery: string;
  move_table_startup: string;
}[]][]

interface CharacterMovesProps {
  moves: CharacterMovesData;
}

const CharacterMoves:React.FC<CharacterMovesProps> = ({moves}) => {
  return (
    <section className={styles.container}>
      <Title type='h2' >
        {!!Number(moves.length) && "moves"}
      </Title>
      {moves.map(move => {
        return (
          <ToggleBox key={move[0]} titleBox={move[0]} >
            <ul id={stringToValidId(move[0])} className='animaDown' >
              {move[1].map(moveBox => {
                const description = splitPragraph(moveBox.move_description);
                const key = Math.random()
                return (
                  <li key={key} className={styles.moveContainer} >
                    <h3>{moveBox.move_title}</h3>
                    <h4>{moveBox.move_subtitle}</h4>
                    <div className={styles.moveBox}>
                      <div>
                        {
                          moveBox.move_images.length === 2 ? 
                          <CharacterMoveHitbox title={moveBox.move_title} images={moveBox.move_images} />
                            : 
                          <Image url={moveBox.move_images[0]} alt={moveBox.move_title} />
                        }
                        <h5>
                          {moveBox.move_image_subtitle}
                        </h5>
                      </div>
                      <div className={styles.tableContainer}>
                        {moveBox.move_table_status === 'on' ? <CharacterTable table={{
                          tableActive: moveBox.move_table_active,
                          tableAdvantage: moveBox.move_table_advantage,
                          tableDamage: moveBox.move_table_damage,
                          tableGuard: moveBox.move_table_guard,
                          tableRecovery: moveBox.move_table_recovery,
                          tableStartup: moveBox.move_table_startup,
                        }} /> : null}
                        <Paragraph font='var(--font-basis)'>
                          {description.map(text => <React.Fragment key={text}>{text} <br /></React.Fragment>)}
                        </Paragraph>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </ToggleBox>
        )
      })
      }
    </section>
  )
}

export default CharacterMoves