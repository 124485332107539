import Head from '../../components/helpers/Head'
import Title from '../../components/ui/Title'
import styles from '../../styles/Music.module.css';
import useFetch from '../../hooks/useFetch';
import { useEffect, useState } from 'react';
import Loading from '../../components/helpers/Loading';
import Error404 from '../Error404';
import { getMusicData } from '../../api/Api';
import useSessionStorage from '../../hooks/useSessionStorage';
import ToggleBox from '../../components/helpers/ToggleBox'
import MusicPlayerContent from './MusicPlayerContent';
import stringToValidId from '../../utils/stringToValidId';
import InternalNav from '../../components/helpers/InternalNav';

export type MusicData = {
  music_name: string;
  music_author_name:string;
  music_author_link?:string;
  music_author_image:string;
  album_image:string;
  music_item_file: string;
  music_item_file_id: string;
}

interface MusicRequestData {
  page_id: number;
  music: [string, MusicData[]][]
}

const Music:React.FC = () => {
  const { loading, error, request } = useFetch<MusicRequestData>();
  const [localData, setLocalData] = useState<MusicRequestData | null>(null);
  const { value, setValueInStorage } = useSessionStorage<MusicRequestData>('music');

  useEffect(() => {
    async function getMusic() {
      const { url, options } = getMusicData();
      const json = await request({url, options});
      if(json) {
        setLocalData(json);
        setValueInStorage(json);
      }
    }

    if(!value) {
      getMusic();
    } else {
      setLocalData(value)
    }
    
  }, [value, request, setValueInStorage])

  if(loading) return <Loading type='page' />;
  if(error) return <Error404 />;
  if(!localData) return null;
  
  return (
    <>
      <InternalNav 
        navigationLinks={[
          {
            offset: 200,
            navigationHeader: "Music",
            navigationLink: localData.music.map(link => link[0])
          }
        ]}
      />
      <main className={`marginMain animaOpacityDown ${styles.container}`}>
        <Head title='Music' />
        <Title type='h2' >
          Music
        </Title>
          {
            localData.music.map(section => {
              return (
                <ToggleBox key={section[0]} titleBox={section[0]} >
                  <ul id={stringToValidId(section[0])} className='animaDown'>
                    {
                      section[1].map(playerData => <MusicPlayerContent key={playerData.music_name} data={playerData} />)
                    }
                  </ul>
                </ToggleBox>
              )
            })
          }
    
      </main>  
    </>
  )
}

export default Music