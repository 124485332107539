import React from 'react'
import Title from '../../components/ui/Title';
import styles from '../../styles/CharacterProsCons.module.css'

export type CharacterProsConsData = {
  pros: {
    pro_title: string;
    pro_description: string;
  }[];
  cons: {
    con_title: string;
    con_description: string;
  }[];
}

interface CharacterProsConsProps {
  prosCons: CharacterProsConsData
}

const CharacterProsCons:React.FC<CharacterProsConsProps> = ({ prosCons }) => {

  return (
    <section id='pros-cons' className={styles.container} >
      <div>
        <Title type='h2' >
          Pros
        </Title>
        <ul>
          {
            prosCons.pros.map(pro => {
              return (
                <li key={pro.pro_title} className={styles.listItem} >
                  <p>
                    <strong><span></span>{pro.pro_title}</strong>
                    {pro.pro_description}
                  </p>
                </li>
              )
            })
          }
        </ul>
      </div>
      <span className={styles.row} ></span>
      <div>
        <Title type='h2' >
          Cons
        </Title>
        <ul>
        
          {
            prosCons.cons.map(con => {
              return (
                <li key={con.con_title} className={styles.listItem} >
                  <p>
                    <strong><span></span>{con.con_title}</strong>
                    {con.con_description}
                  </p>
                </li>
              )
            })
          }
        </ul>
      </div>
    </section>
  )
}

export default CharacterProsCons