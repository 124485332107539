import React, { useEffect, useState } from 'react'
import { getWorldData } from '../api/Api';
import Head from '../components/helpers/Head'
import Loading from '../components/helpers/Loading';
import Paragraph from '../components/ui/Paragraph';
import Title from '../components/ui/Title';
import useFetch from '../hooks/useFetch'
import { splitPragraph } from '../utils/splitParagraph';
import styles from '../styles/World.module.css';
import Error404 from './Error404';
import useSessionStorage from '../hooks/useSessionStorage';
import Image from '../components/helpers/Image';
import InternalNav from '../components/helpers/InternalNav';
import stringToValidId from '../utils/stringToValidId';

interface WorldRequestData {
  page_id: number;
  world_paragraphs: {
    world_title:string;
    world_paragraph: string;
    world_big_paragraph?: string;
    world_Image_title?: string;
    paragraph_image_id: number;
    paragraph_image: string;
  }[];
}

const World:React.FC = () => {
  const { loading, error, request } = useFetch<WorldRequestData>();
  const [localData, setLocalData] = useState<WorldRequestData | null>(null);
  const { value, setValueInStorage } = useSessionStorage<WorldRequestData>('world');

  useEffect(() => {
    async function getWorld() {
      const { url, options } = getWorldData();
      const json  = await request({url, options});

      if(json) {
        setLocalData(json);
        setValueInStorage(json);
      }
    };
  
    if(!value) {
      getWorld();
    } else {
      setLocalData(value);
    }

  }, [request, value, setValueInStorage])

  if(loading) return <Loading type='page' />;
  if(error) return <Error404 />;
  if(!localData) return null;

  return (
    <>
      <InternalNav
        navigationLinks={[
          {
            navigationHeader: 'Stories',
            navigationLink: localData.world_paragraphs.map(paragraph => paragraph.world_title),
            offset: 180
          }
        ]}
      />
      <main className='marginMain animaOpacityDown'>
        <Head title='World' />
        {
          localData.world_paragraphs.map(paragraph => {
            const sideParagraph = splitPragraph(paragraph.world_paragraph);
            const bigParagraph = paragraph.world_big_paragraph ? splitPragraph(paragraph.world_big_paragraph) : null;

            return (
              <section id={stringToValidId(paragraph.world_title)} className={styles.container} key={paragraph.paragraph_image_id}>
                <Title type='h2'>
                  {paragraph.world_title}
                </Title>
                <div className={styles.paragraphContainer}>
                  <div>
                    <Image url={paragraph.paragraph_image} alt={paragraph.world_title} />
                    <Title type='h3'>
                      {paragraph.world_Image_title}
                    </Title>
                  </div>
                  <Paragraph>
                    {sideParagraph.map(sidePa => {
                      return <React.Fragment key={sidePa}>&emsp;{sidePa} <br /> </React.Fragment>
                    })}
                  </Paragraph>
                </div>
                <Paragraph>
                  {bigParagraph && bigParagraph.map(bigPa => {
                      return <React.Fragment key={bigPa}>&emsp;{bigPa} <br /></React.Fragment>
                  })}
                </Paragraph>
              </section>
            )
          })
        }
      </main>
    </>
  )
}

export default World