import React from 'react';
import styles from '../../styles/Title.module.css';

type TitleTypes = 'h2' | 'h3' | 'h4';

interface TitleProps {
  children: React.ReactNode
  type: TitleTypes;
}

const Title:React.FC<TitleProps> = ({children,type}) => {

  switch(type) {
    case 'h2':
      return <h2 className={`${styles.title} ${styles.h2}`}>{children}</h2>;
    case 'h3':
      return <h3 className={`${styles.title} ${styles.h3}`}>{children}</h3>;
    case 'h4':
      return <h4 className={`${styles.title} ${styles.h4}`}>{children}</h4>;
    default: 
      return null;
  }
}

export default Title