import React, { useState } from 'react'
import toHttps from '../../utils/toHttps';
import Loading from './Loading';
import styles from '../../styles/Image.module.css'

interface SkeletonProps {
  url: string;
  alt?: string;
}

const Image:React.FC<SkeletonProps> = ({ url, alt }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  function handleLoad() {
    setIsLoading(false);
  }

  return( 
    <div className={`${isLoading ? styles.container : ''}`}>
      {isLoading && <Loading type='circular' />}
      <img onLoad={handleLoad} src={toHttps(url)} alt={alt ? alt : ''} />
    </div>
  )
}

export default Image