import React, { useState } from 'react'
import Image from '../../components/helpers/Image';
import styles from '../../styles/CharacterMoveHitbox.module.css'

type selectTypes = 'move' | 'hitbox';

interface CharacterMoveHitboxProps {
  title: string;
  images: [string, string];
}

const CharacterMoveHitbox:React.FC<CharacterMoveHitboxProps> = ({ title ,images }) => {
  const [selectedImage, setSelectedImage] = useState<selectTypes>('move');
  const selectImage = {
    move: <Image url={images[0]} alt={`${title} Move Image`} />,
    hitbox: <Image url={images[1]} alt={`${title} Hitbox Image`} />
  }

  return (
    <div className={styles.container} >
      <div className={styles.selection} >
        <button 
          className={`${selectedImage === 'move' ? styles.active : null }`}
          onClick={() => setSelectedImage('move')} 
        >
          move
        </button>
        <button 
          className={`${selectedImage === 'hitbox' ? styles.active : null }`}
          onClick={() => setSelectedImage('hitbox')}
        >
          hitbox
        </button>
      </div>
      <div>
        {selectImage[selectedImage]}
      </div>
    </div>
  )
}

export default CharacterMoveHitbox