import React from 'react'
import Image from '../../components/helpers/Image';
import CharactersPicker from '../../components/ui/CharactersPicker'
import styles from '../../styles/HomeCharacters.module.css';

export type CharacterRequestData = {
  character_title:string;
  character_portraits: {
    character_name:string;
    character_page_link: string;
    character_portrait_image: string;
    character_portrait_image_id: number;
  }[]
}
interface PickCharactersProps {
  characterData: CharacterRequestData
}

const PickCharacters:React.FC<PickCharactersProps> = ({characterData}) => {
  return (
    <section className={styles.container} id='characters'>
      <h2 className='imageTitle'>
        <Image url={characterData.character_title} alt="Pick your character" /> 
      </h2>
      <nav className={styles.navCharacters}>
        <CharactersPicker />
      </nav>
    </section>
  )
}

export default PickCharacters