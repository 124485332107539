import React from 'react'
import Image from '../../components/helpers/Image';
import Title from '../../components/ui/Title';
import styles from '../../styles/CharacterColors.module.css'

export type CharacterColorsData = {
  character_color_name: string;
  character_color_image_id: number;
  character_color_image: string;
}[];
interface CharacterColorsProps {
  colors: CharacterColorsData;
}

const CharacterColors:React.FC<CharacterColorsProps> = ({ colors }) => {
  return (
    <section className={styles.container} id='colors'>
      <Title type='h2' >
        Colors
      </Title>
      <div>
        <ul className={styles.colors} >
          {
            colors.map(color => {
              return (
                <li className={styles.color} key={color.character_color_name} >
                  <Image url={color.character_color_image} alt={`Character ${color.character_color_name}`} />
                  <h4>
                    {color.character_color_name}
                  </h4>
                </li>
              )
            })
          }
        </ul>
      </div>
    </section>
  )
}

export default CharacterColors