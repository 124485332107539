import React from 'react'
import { splitPragraph } from '../../utils/splitParagraph';
import Paragraph from '../../components/ui/Paragraph';
import styles from '../../styles/HomeAbout.module.css';
import Image from '../../components/helpers/Image';
import { Link } from 'react-router-dom';

export type AboutRequestData = {
  about_paragraph_left:string;
  about_paragraph_right: string;
  about_title: string;
  game_download_link: string;
}
interface AboutProps {
  aboutData: AboutRequestData;
}

const About:React.FC<AboutProps> = ({aboutData}) => {
  const aboutParagraphLeft = splitPragraph(aboutData.about_paragraph_left);
  const aboutParagraphRight = splitPragraph(aboutData.about_paragraph_right);

  return (
    <section id='about'>
      <h2 className='imageTitle'>
        <Image url={aboutData.about_title} alt="About the game" />
      </h2>
      <div className={styles.paragraphContainer}>
        <Paragraph>
          {aboutParagraphLeft.map(paragraph => {
            return <React.Fragment key={paragraph}>&emsp; {paragraph} <br /></React.Fragment>
          })}
        </Paragraph>
        <span className='row'></span>
        <Paragraph>
          {aboutParagraphRight.map(paragraph => {
            return <React.Fragment key={paragraph}>&emsp; {paragraph} <br /></React.Fragment>
          })}
        </Paragraph>
      </div>
      <a className={styles.downloadButton} href={aboutData.game_download_link} target="blank">download</a>
      <Link
          to="/download"
          className={styles.seeMoreButton}
      >See more options</Link>
    </section>
  )
}

export default About