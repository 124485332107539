import React, { useState } from 'react'
import styles from '../../styles/ToggleBox.module.css';

interface ToggleBoxProps {
  titleBox: string;
  children: React.ReactNode;
}

const ToggleBox:React.FC<ToggleBoxProps> = ({titleBox, children}) => {
  const [toggleBox, setToggleBox] = useState<boolean>(true);

  return (
    <section className={styles.container}>
      <h2 className={`${styles.arrow} ${toggleBox ? styles.arrowUp : styles.arrowDown}`} onClick={() => setToggleBox(curr => !curr)}>{titleBox}</h2>
      {toggleBox && children}
    </section>
  )
}

export default ToggleBox