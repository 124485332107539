import { useEffect } from 'react'

const Head = ({title}:{title?:string;}) => {
  useEffect(() => {
    if(!title) {
      document.title = `Sakura X Clash - Play now!`;
    } else if(title === '404') {
      document.title = `Page Not Found - 404`
    } else {
      document.title = `${title} - sXc - Wiki`;
    }
  }, [title])

  return null;
}

export default Head