import { useCallback, useState } from "react";

interface useFetchParams {
  url: string;
  options: {
    method:string;
  }
}

const useFetch =<dataType>() => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const request = useCallback( async ({ url, options }:useFetchParams) => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(url, options);
      const json:dataType = await response.json();
  
      if(response.ok) {
        return json;
      } else throw new Error(`Response not ok: ${response.status}`);
    } catch(err:any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []) 
  
  return {
    loading,
    error,
    request
  }
}

export default useFetch;