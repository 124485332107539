import React from "react";

const smoothScrollTo = (eventAnchor:React.MouseEvent<HTMLAnchorElement>, offset:number = 100) => {
  eventAnchor.preventDefault();
  const target = eventAnchor.target as HTMLAnchorElement;
  const id = target.getAttribute('href')?.toString();
  const refatoredId = id?.includes('/') ? id.split('#')[1] : id;
  if(!id) return;
  const elementToScroll = document.querySelector(`#${refatoredId}`);
  if(!elementToScroll) return;
  const distantToTop = elementToScroll.getBoundingClientRect().y  - offset;
  if(!distantToTop || +distantToTop === 0) return;
  window.scrollBy({
    top: distantToTop,
    behavior:'smooth',
  })
};

export default smoothScrollTo;