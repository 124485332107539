import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../../styles/CookieBanner.module.css';
import ReactGA from 'react-ga4';

const TRAKING_ID = 'G-241WT2TEW1';

type Token = {
  time: number,
  cookies: boolean
}

const initializeGA = () => {
  ReactGA.initialize(TRAKING_ID);
  return true;
}

const CookieBanner: React.FC = () => {
  const [isTokenValid, setIsTokenValid] = useState<boolean>(true);
  const { pathname } = useLocation();
  const isGAInitialized = useRef<boolean>(false);;

  const createToken = (status:boolean) => {
    const token:Token = {
      time: Date.now(),
      cookies: status
    }

    window.localStorage.setItem('co-token', JSON.stringify(token))
    return token;
  }

  const checkToken = (token:Token) => {
    const timeNow = Date.now();
    const validate = timeNow - token.time <= 1000 * 60 * 60 * 24 * 2
    return validate
  }

  const denyCookie = () => {
    setIsTokenValid(true);
    createToken(false);
    isGAInitialized.current = false;
  }

  const acceptCookie = () => {
    setIsTokenValid(true);
    createToken(true);
    isGAInitialized.current = initializeGA();
  }

  useEffect(() => {
    if(isGAInitialized.current) {
      ReactGA.send({
        hitType: "pageview",
        page: pathname
      });
    }
  }, [pathname])

  useLayoutEffect(() => {
    const stringToken = window.localStorage.getItem('co-token');
    if(!stringToken) return setIsTokenValid(false);
    const localToken:Token = JSON.parse(stringToken);
    if(localToken.cookies) {
      const isValid = checkToken(localToken)
      isValid ? isGAInitialized.current = initializeGA() : window.localStorage.removeItem('co-token');
    } else {
      window.localStorage.removeItem('co-token')
    }
  }, [])

  if(isTokenValid) return null;
  return (
    <div className={styles.container} >
      <div>
        <h2>
          The Website uses cookies
        </h2>
        <p>
          This website use cookies from 
          <a 
            href="https://analytics.google.com/" target='blank' 
            rel='external'
          >
             Google Analytics,
          </a>
         in order to collect traffic info and improve your browsing experience.
        </p>
      </div>
      <div>
        <button onClick={denyCookie} >
          Deny
        </button>
        <button onClick={acceptCookie} >
          Accept
        </button>
      </div>
    </div>
  )
}

export default CookieBanner