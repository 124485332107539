import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import styles from '../../styles/InternalNav.module.css';
import smoothScrollTo from '../../utils/smoothScrollTo';
import stringToValidId from '../../utils/stringToValidId';

interface InternalGroupLinksProps {
  link: {
    offset?:number;
    navigationHeader: string | null;
    navigationLink: string | string[];
  }
}

const InternalGroupLinks:React.FC<InternalGroupLinksProps> = ({link}) => {
  const [ toggle, setToggle ] = useState<boolean>(false);

  if(!Array.isArray(link.navigationLink)) return null;

  return (
    <li>
      {
        link.navigationHeader && (
        <h2 onClick={() => setToggle(curr => !curr)} className={`${styles.linkTitle} ${styles.arrow} ${toggle ? styles.arrowUp : styles.arrowDown}`}>
          {link.navigationHeader}
        </h2>
        )
      }
      {
        toggle && (
          <ul className={styles.animation} >
            {
              link.navigationLink.map(sublink => {
              return (
              <li className={styles.sublink} key={sublink + Math.random()}>
                <Link onClick={(e) => {
                  if(link.offset) {
                    smoothScrollTo(e, link.offset)
                  } else {
                    smoothScrollTo(e)
                  }
                }} to={`#${stringToValidId(sublink)}`} >
                  {sublink}
                </Link>
              </li>)
              })
            }
          </ul>
        )
      }
    </li>
  )
}

export default InternalGroupLinks