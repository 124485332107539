import React, { createContext, useState } from 'react'
import { MusicData } from '../pages/Music';

export interface ExternalMusicContextType {
  data: MusicData | null;
  changeData: (data:MusicData | null) => void;
}

export const ExternalMusicContext = createContext<ExternalMusicContextType | null>(null);

ExternalMusicContext.displayName = "External Music Context";

interface ExtenalMusicProviderProps {
  children: React.ReactNode
}

const ExtenalMusicProvider:React.FC<ExtenalMusicProviderProps> = ({children}) => {
  const [data, setData] = useState<MusicData | null>(null);

  const changeData = (data:MusicData | null) => {
    setData(data)
  };

  return (
    <ExternalMusicContext.Provider value={{
      data,
      changeData
    }}>
      {children}
    </ExternalMusicContext.Provider>
  )
}

export default ExtenalMusicProvider