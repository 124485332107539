import { useEffect, useState } from 'react'
import { getPortraitsData } from '../../api/Api';
import useFetch from '../../hooks/useFetch';
import { HomeRequestData } from '../../pages/Home';
import Loading from '../helpers/Loading';
import styles from '../../styles/CharactersPicker.module.css';
import useSessionStorage from '../../hooks/useSessionStorage';
import { Link } from 'react-router-dom';
import Image from '../helpers/Image';

const CharactersPicker = () => {
  const { loading , request } = useFetch<HomeRequestData>();
  const [localData, setLocalData] = useState<HomeRequestData | null>(null);
  const { value, setValueInStorage } = useSessionStorage<HomeRequestData>('portraits');

  useEffect(() => {
    async function getPortraits() {
      const {url, options} = getPortraitsData();
      const json = await request({url, options});
      if(json){
        setLocalData(json);
        setValueInStorage(json);
      }
    }

    if(!value) {
      getPortraits();
    } else {
      setLocalData(value);
    };

  }, [request, value, setValueInStorage])

  if(loading) return <Loading type='circular' />;
  if(!localData) return null;

  return (
    <ul className={styles.container} role={'menu'}>
      {localData.character.character_portraits.map(portrait => {
        return (
        <li key={portrait.character_portrait_image_id}>
          <Link to={`characters/${portrait.character_page_link}`} data-tip={portrait.character_name} >
            <Image url={portrait.character_portrait_image} alt={portrait.character_name} />
          </Link>
        </li>
        )
      })}
    </ul>
  )
}

export default CharactersPicker