import React from 'react'
import Title from '../../components/ui/Title';
import star from '../../assets/star.png';
import styles from '../../styles/CharacterProfile.module.css';
import CharacterProfileDescriptions from './CharacterProfileDescriptions';
import stringToValidId from '../../utils/stringToValidId';
import Image from '../../components/helpers/Image';

export type CharacterProfileData = {
  character_name: string;
  character_art: string;
  character_dificulty: string | number;
  character_story: string;
  character_characteristics: [string, string][];
  character_overview: {
    archetype: string;
    status: [string, string][];
    description: string;
  }
}
interface CharacterProfileProps {
  profile: CharacterProfileData;
}

const CharacterProfile:React.FC<CharacterProfileProps> = ({profile}) => {
  const difficultyArray = new Array(Number(profile.character_dificulty)).fill(0);

  return (
    <section id={stringToValidId(profile.character_name)} className={styles.container} >
      <div className={styles.profile}>
        <Title type='h3'>
          {profile.character_name}
        </Title>
        <Image url={profile.character_art} alt={`${profile.character_name} Art`} />
        <div className={styles.difficulty}>
          <Title type='h3'>
            difficulty:
          </Title>
          <ul>
            {
              difficultyArray.map((item, index) => {
                return (
                  <li key={index}>
                    <img src={star} alt="Difficulty Star" />
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
      <CharacterProfileDescriptions profile={profile} />
    </section>
  )
}

export default CharacterProfile