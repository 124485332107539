import React from 'react'
import Paragraph from '../../components/ui/Paragraph'
import { splitPragraph } from '../../utils/splitParagraph'
import styles from '../../styles/HomeMechanics.module.css';
import Image from '../../components/helpers/Image';

export type MechanicsRequestData = {
  game_image: {
    1: string;
    2: string;
  };
  mechanics_description: string;
  mechanics_title: string;
}
interface MechanicsProps {
  mechanicsData:MechanicsRequestData
}

const Mechanics:React.FC<MechanicsProps> = ({mechanicsData}) => {
  const mechanicsDescription = splitPragraph(mechanicsData.mechanics_description)

  return (
    <section className={styles.container}>
      <h2 className='imageTitle'>
        <Image url={mechanicsData.mechanics_title} alt="Mechanics title" />
      </h2>
      <div className={styles.paragraphContainer}>
        <Paragraph>
          {mechanicsDescription.map((paragraph, index) => {
            const lastIndex = mechanicsDescription.length - 1;

            return <React.Fragment key={paragraph}>&emsp; {paragraph}{lastIndex === index && <a className={styles.readmore} href="/mechanics" rel='next'><strong>Read more...</strong></a>} <br /></React.Fragment>
          })}
          
        </Paragraph>
        <span className='row'></span>
        <div className={styles.imageContainer}>
          <Image url={mechanicsData.game_image[1]} alt="Game Battle 1" />
          <Image url={mechanicsData.game_image[2]} alt="Game Battle 2" />
        </div>
      </div>
    </section>
  )
}

export default Mechanics